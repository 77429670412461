<template>
  <div id="data-list-list-view" class="data-list-container">

    <div v-if="initProgress" class="w-100 mt-5 mb-5">
      <vs-progress indeterminate color="primary"></vs-progress>
    </div>

    <vs-prompt title="Exportar a Excel" class="export-options" @cancle="clearFields" @accept="exportToExcel"
               accept-text="Exportar" cancel-text="Cancelar" @close="clearFields" :active.sync="activePrompt">
      <vs-input v-model="fileName" placeholder="Ingresa el nombre del archivo..." class="w-full"/>
      <v-select v-model="selectedFormat" :options="formats" class="my-4"/>
      <div class="flex">
        <span class="mr-4">Auto ajustar columna:</span>
        <vs-switch v-model="cellAutoWidth">Auto ajustar columna</vs-switch>
      </div>
    </vs-prompt>

    <vs-prompt title="Exportar a Excel" class="export-options" @cancle="clearFields" @accept="exportToExcelCommissions"
               accept-text="Exportar" cancel-text="Cancelar" @close="clearFields"
               :active.sync="activePromptCommissions">
      <vs-input v-model="fileName" placeholder="Ingresa el nombre del archivo..." class="w-full"/>
      <v-select v-model="selectedFormat" :options="formats" class="my-4"/>
      <div class="flex">
        <span class="mr-4">Auto ajustar columna:</span>
        <vs-switch v-model="cellAutoWidth">Auto ajustar columna</vs-switch>
      </div>
    </vs-prompt>

    <new :quotes="quotes" @add="addDataSideBar" :isSidebarActive="showNewSidebar"
         @closeSidebar="showNewSidebar= false"/>

    <movements :data="sidebarData" :isSidebarActive="showMovements"
               @closeSidebar="showMovements= false"/>

    <new-w-q :clients="clients" :products="products" @add="addDataSideBar"
             :isSidebarActive="showNewSidebarWC"
             @closeSidebar="showNewSidebarWC= false"/>

    <payments
        @update="updateDataSideBar"
        :data="sidebarData"
        :isSidebarActive="showPaymentsSidebar"
        @closeSidebar="showPaymentsSidebar=false"/>

    <vx-card collapse="false" ref="filterCard" title="Filtros" class="user-list-filters mb-8" collapse-action
             refresh-content-action @refresh="resetFilters">
      <!--Filter by date-->
      <div>
        <h6 class="mt-2">Rango de fechas</h6>
        <!--Dates-->
        <div class="vx-row mt-4">
          <div class="vx-col md:w-1/2">
            <label class="text-sm opacity-75">Desde*</label>
            <datepicker :language="es" label="Desde" v-model="startDate" id="startDate"
                        v-validate="'required'" name="startDate"></datepicker>
          </div>
          <div class="vx-col md:w-1/2">
            <label class="text-sm opacity-75">Hasta*</label>
            <datepicker :language="es" label="Gasta" v-model="endDate" id="endDate"
                        v-validate="'required'" name="startDate"></datepicker>
          </div>
        </div>
        <!--End-->
        <!--Clients-->
        <div>
          <div class="vx-row">
            <div class="vx-col md:w-1/2">
              <h6 class="mt-5 mb-2">Selecciona cliente</h6>
              <v-select :options="clients" :clearable="true"
                        :dir="$vs.rtl ? 'rtl' : 'ltr'"
                        v-model="client" class="mb-4 md:mb-0" name="client" id="client"/>
            </div>
            <div v-if="$acl.check('admin')" class="vx-col md:w-1/2">
              <h6 class="mt-5 mb-2">Desde</h6>
              <vs-input v-model.number="from" class="w-full"></vs-input>
            </div>
          </div>
        </div>
      </div>
      <div class="vx-col mt-2">
        <vs-button @click="filterByDate" class="mr-6 vs-align-right">Filtrar</vs-button>
      </div>
    </vx-card>

    <vs-table v-model="selected" ref="table" pagination :max-items="itemsPerPage" search
              :data="list">
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
          <!-- Action - dropnow -->
          <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer mr-4">
            <div
                class="p-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full">
              <span class="mr-2">Acciones</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="activePrompt=true">
                            <span class="flex items-center">
                              <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Exportar (Formato para Excel)</span>
                            </span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="activePromptCommissions=true">
                            <span class="flex items-center">
                              <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Exportar Comisiones</span>
                            </span>
              </vs-dropdown-item>
              <vs-dropdown-item
                  v-if="selected.lowCommunicationNubefact"
                  @click="selected.lowCommunicationNubefact ? openLink(selected.lowCommunicationNubefact.enlace_del_pdf): ''">
                            <span class="flex items-center">
                              <feather-icon
                                  icon="ExternalLinkIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Ver comunicación de baja</span>
                            </span>
              </vs-dropdown-item>
              <vs-dropdown-item
                  v-if="selected.id"
                  @click="selected.nubefact ? openLink(selected.nubefact.enlace_del_pdf): ''">
                            <span class="flex items-center">
                              <feather-icon
                                  icon="ExternalLinkIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Ver documento (O)</span>
                            </span>
              </vs-dropdown-item>
              <vs-dropdown-item v-if="selected.status === 'send' && $acl.check('admin')" @click="recordPayment">
                            <span class="flex items-center">
                              <feather-icon icon="DollarSignIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Registrar pago (P)</span>
                            </span>
              </vs-dropdown-item>
              <vs-dropdown-item
                  v-if="selected.status === 'send' || selected.status === 'rejected'"
                  @click="openPrintDialog(selected)">
                            <span class="flex items-center">
                              <feather-icon
                                  icon="PrinterIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Imprimir (I)</span>
                            </span>
              </vs-dropdown-item>
              <vs-dropdown-item
                  v-if="selected.status === 'send'"
                  @click="openCancelDialog(selected)">
                            <span class="flex items-center">
                              <feather-icon
                                  icon="XIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Anular (A)</span>
                            </span>
              </vs-dropdown-item>
              <vs-dropdown-item
                  v-if="selected.status === 'hold'"
                  @click="openResendDialog(selected)">
                            <span class="flex items-center">
                              <feather-icon
                                  icon="UploadIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Reenviar (R)</span>
                            </span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
          <!-- Add new -->
          <div
              class="btn-add-new p-3 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary"
              @click="showNewSidebar= true">
            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4"/>
            <span class="ml-2 text-base text-primary">Agregar comp. regular</span>
          </div>
          <!-- Add new -->
          <div
              class="btn-add-new p-3 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary"
              @click="showNewSidebarWC= true">
            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4"/>
            <span class="ml-2 text-base text-primary">Agregar comp. sin cotización</span>
          </div>
        </div>

        <!-- Items per page -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
          <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{
                currentPage * itemsPerPage - (itemsPerPage - 1)
              }} - {{ list.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : list.length }} de {{
                queriedItems
              }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
          </div>
          <vs-dropdown-menu>

            <vs-dropdown-item @click="itemsPerPage=10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=20">
              <span>20</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=30">
              <span>30</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=40">
              <span>40</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="client">Cliente</vs-th>
        <vs-th sort-key="sequence">Nro. Doc.</vs-th>
        <vs-th sort-key="subTotal">Subtotal</vs-th>
        <vs-th sort-key="total">Total</vs-th>
        <vs-th sort-key="paidOut">Por cobrar</vs-th>
        <vs-th sort-key="status">Estado</vs-th>
        <vs-th sort-key="paymentType">Tipo de pago</vs-th>
        <vs-th sort-key="registrationDate">Registro</vs-th>
        <vs-th sort-key="deadline">Vencimiento</vs-th>
        <vs-th sort-key="cot">Cot.</vs-th>
        <vs-th sort-key="actions">Acciones</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody>
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

          <vs-td>
            <p class="font-medium truncate product-name">{{ tr.client.businessName }}</p>
          </vs-td>

          <vs-td>
            <p class="font-medium truncate">{{ tr.code }}</p>
          </vs-td>

          <vs-td>
            <p>
              {{
                Intl.NumberFormat('en-IN', {
                  style: 'currency',
                  currency: tr.currency.code,
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(tr.subTotal)
              }}
            </p>
          </vs-td>

          <vs-td>
            <p>
              {{
                Intl.NumberFormat('en-IN', {
                  style: 'currency',
                  currency: tr.currency.code,
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(tr.total)
              }}
            </p>
          </vs-td>

          <vs-td>
            <vs-chip color="danger" class="product-order-status">
              {{
                Intl.NumberFormat('en-IN', {
                  style: 'currency',
                  currency: tr.currency.code,
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(tr.total - tr.paidOut)
              }}
            </vs-chip>
          </vs-td>

          <vs-td>
            <vs-chip :color="getStatusColor(tr.status)" class="product-order-status">
              {{ getStateText(tr.status) | title }}
            </vs-chip>
          </vs-td>

          <vs-td>
            <p>
              <template v-if="tr.paymentType && tr.paymentType === 'regular'">Regular</template>
              <template v-else-if="tr.paymentType && tr.paymentType === 'fees'">A Cuotas: {{ tr.fees.value }} {{ tr.frequency.value }}
              </template>
              <template v-else>-</template>
            </p>
          </vs-td>

          <vs-td>
            <p class="font-medium truncate">{{ tr.createdAt | date(true) }}</p>
          </vs-td>

          <vs-td>
            <p class="font-medium truncate">{{ tr.dueDate | date(true) }}</p>
          </vs-td>

          <vs-td>
            <template v-if="tr.quote">
              <vs-chip @click.native="openLink(tr.quote.link)" color="dark" class="product-order-status">
                {{ tr.quote.code }}
              </vs-chip>
            </template>
            <template v-else>
              <p>NA</p>
            </template>
          </vs-td>

          <vs-td class="whitespace-no-wrap">

            <feather-icon @click="openLink(tr.nubefact.enlace_del_pdf)"
                          v-if="tr.status === 'send' || tr.status === 'rejected'" title="Link"
                          icon="ExternalLinkIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current mr-3"/>

            <feather-icon v-if="tr.quote" @click="showMovements = true, sidebarData = tr"
                          title="Movimientos"
                          icon="EyeIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current mr-3"/>

            <feather-icon @click="openCancelDialog(tr)" v-if="tr.status === 'send'" title="Anular"
                          icon="XIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current mr-3"/>


            <feather-icon @click="openResendDialog(tr)" v-if="tr.status === 'hold'" title="Reenviar"
                          icon="UploadIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current mr-3"/>

            <feather-icon @click="openPrintDialog(tr)" v-if="tr.status === 'send'" title="Imprimir"
                          icon="PrinterIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current mr-3"/>

            <feather-icon @click="recordPayment" v-if="tr.status === 'send' && $acl.check('admin')"
                          title="Registrar pago"
                          icon="DollarSignIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current"/>

            <feather-icon @click="faqDialog(tr)" v-if="tr.status === 'not_send'" title="Detalle de error"
                          icon="HelpCircleIcon"
                          svgClasses="w-5 h-5 mr-5 hover:text-primary stroke-current"/>

            <feather-icon v-if="tr.status === 'not_send'" @click="getStatus(tr)" title="Verificar estado"
                          icon="DownloadIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current"/>
          </vs-td>
        </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker'

import { auth, db, Timestamp } from '@/firebase/firebaseConfig'
import openLink from '@/mixins/link'
import New from '@/views/sales/New'
import NewWQ from '@/views/sales/NewWQ'
import Payments from './Payments'
import Movements from './QuoteMovements'
//import Update from '@/views/purchases/Update'
import axios from 'axios'
import { es } from 'vuejs-datepicker/dist/locale'

const algoliasearch = require('algoliasearch')

const client = algoliasearch('XQUSDVRT6D', 'f5a3a884ae2750149d9d5e052e447506')
const index = client.initIndex('products')

export default {
  name: 'SalesList',
  components: {
    Movements,
    vSelect,
    New,
    //Update,
    Datepicker,
    NewWQ,
    Payments
  },
  mixins: [openLink],
  data () {
    return {
      selected: {},
      itemsPerPage: 10,
      isMounted: false,
      list: [],
      quotes: [],
      clients: [],
      products: [],
      initProgress: false,

      // Data Sidebar
      showNewSidebar: false,
      showNewSidebarWC: false,
      sidebarData: {},
      showCancel: false,
      showPaymentsSidebar: false,
      showMovements: false,
      // Export
      fileName: '',
      formats: ['xlsx', 'csv', 'txt'],
      cellAutoWidth: true,
      selectedFormat: 'xlsx',
      activePrompt: false,
      activePromptCommissions: false,
      selectedInvoice: {},
      // Filter
      startDate: null,
      endDate: null,
      es: es,
      client: null,
      evt: null,
      from: 0
    }
  },
  computed: {
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.list.length
    }
  },
  async created () {
    try {
      this.initProgress = true
      await this.loadSales()
      await this.loadQuotes()
      await this.loadClients()
      await this.loadProducts()
      // Set filter las 30 days
      this.startDate = new Date(new Date().setDate(new Date().getDate() - 30))
      this.endDate = new Date()
    } catch (e) {
      console.log(e)
      this.$vs.notify({
        color: 'warning',
        title: '¡Oh no!',
        text: 'Hubo un error.'
      })
    } finally {
      this.initProgress = false
    }
  },
  mounted () {
    this.isMounted = true
    // Listen keyboard
    // this.evt = window.document
    // this.evt.addEventListener('keyup', this.listenKeyboard)
  },
  destroyed () {
    this.evt.removeEventListener('keyup', this.listenKeyboard)
  },
  methods: {
    /**
     * Regular export
     */
    exportToExcel () {
      import('@/vendor/Export2Excel').then(excel => {
        const _ = require('lodash')
        const list = _.cloneDeep(this.list)
        list.forEach((o) => {
          o.client = o.client.businessName
          o.date = o.createdAt.getFullYear() + '-' + (o.createdAt.getMonth() + 1) + '-' + o.createdAt.getDate()
          o.deadline = o.dueDate.getFullYear() + '-' + (o.dueDate.getMonth() + 1) + '-' + o.dueDate.getDate()
          o.user = o.user.displayName
          if (o.status !== 'rejected') {
            o.dollars = o.currency.code === 'USD' ? o.total : 0
            o.pen = o.currency.code === 'PEN' ? o.total : 0
            o.retentionDollar = o.currency.code === 'USD' ? (o.regimeType.percentage === 3 ? o.total * 0.97 : 0) : 0
            o.retentionPEN = o.currency.code === 'PEN' ? (o.regimeType.percentage === 3 ? o.total * 0.97 : 0) : 0
            o.detractionDollar = o.currency.code === 'USD' ? (o.regimeType.percentage === 12 ? o.total * 0.88 : 0) : 0
            o.detractionDollar = o.currency.code === 'USD' ? (o.regimeType.percentage === 10 ? o.total * 0.88 : 0) : 0
            o.detractionPEN = o.currency.code === 'PEN' ? (o.regimeType.percentage === 12 ? o.total * 0.88 : 0) : 0
            o.detractionPEN = o.currency.code === 'PEN' ? (o.regimeType.percentage === 10 ? o.total * 0.88 : 0) : 0
          }
          else {
            o.dollars = 0
            o.pen = 0
            o.retentionDollar = 0
            o.retentionPEN = 0
            o.detractionDollar = 0
            o.detractionPEN = 0
          }

        })
        const headerVal = ['client', 'code', 'date', 'deadline', 'user', 'dollars', 'pen', 'retentionDollar', 'retentionPEN', 'detractionDollar', 'detractionPEN']
        const headerTitle = ['Cliente', 'Nro. Comp.', 'Fecha de Emisión', 'Fecha de vencimiento', 'Vendedor', 'Dólares', 'Soles', 'Retención $', 'Retención S/', 'Detracción $', 'Detracción S/']
        const data = this.formatJson(headerVal, list)
        excel.export_json_to_excel({
          header: headerTitle,
          data,
          filename: this.fileName,
          autoWidth: this.cellAutoWidth,
          bookType: this.selectedFormat
        })
        this.clearFields()
      })
    },
    /**
     * Commissions export
     */
    exportToExcelCommissions () {
      try {
        import('@/vendor/Export2Excel').then(async excel => {
          this.initProgress = true
          const _ = require('lodash')
          const list = _.cloneDeep(this.list)
          // Get Purchases
          for await (let sale of list) {
            sale.quoteMovements = []
            if (sale.type === 'regular') {
              const quoteMovements = await db.collection('quotes').doc(sale.quote.id).collection('quotesMovements').get()
              quoteMovements.forEach((doc) => {
                sale.quoteMovements.push({
                  ...doc.data()
                })
              })
            }
          }
          list.forEach((o) => {
            o.businessName = o.client.businessName
            o.state = this.getStateText(o.status)
            o.currency = o.currency.value
            o.date = o.createdAt.getFullYear() + '-' + (o.createdAt.getMonth() + 1) + '-' + o.createdAt.getDate()
            o.total = o.status === 'send' ? o.total : 0
            o.totalWithoutIGV = o.status === 'send' ? parseFloat((o.total / 1.18).toFixed(2)) : 0
            o.exchangeRatePurchase = o.type === 'regular' ? (o.quoteMovements.filter((m) => m.type === 'purchase')[0] ? o.quoteMovements.filter((m) => m.type === 'purchase')[0].exchangeRate : 0) : 0
            //
            o.totalPurchaseWithoutIGV = 0
            if (o.status === 'send') {
              o.quoteMovements.forEach((m) => {
                if (m.type === 'purchase') {
                  if (o.currency.code === 'USD' && m.currency.code === 'PEN') {
                    o.totalPurchaseWithoutIGV += (m.total / m.exchangeRate / 1.18)
                  }
                  else if (o.currency.code === 'PEN' && m.currency.code === 'USD') {
                    o.totalPurchaseWithoutIGV += (m.total * m.exchangeRate / 1.18)
                  }
                  else {
                    o.totalPurchaseWithoutIGV += m.total / 1.18
                  }
                }
              })
              o.totalPurchaseWithoutIGV = parseFloat(o.totalPurchaseWithoutIGV.toFixed(2))
            }
            //
            o.profitPercentage = o.type === 'regular' ? parseFloat(((o.totalWithoutIGV - o.totalPurchaseWithoutIGV) * 100 / o.totalWithoutIGV).toFixed(2)) : 0
            o.profitTotal = o.type === 'regular' ? parseFloat(((o.totalWithoutIGV - o.totalPurchaseWithoutIGV)).toFixed(2)) : 0
            o.percentageToClient = 0
            o.clientAmount = 0
            o.finalProfit = o.type === 'regular' ? parseFloat(((o.totalWithoutIGV - o.totalPurchaseWithoutIGV)).toFixed(2)) : 0
            o.sellerPercentage = 10
            o.sellerProfit = o.type === 'regular' ? o.finalProfit * o.sellerPercentage / 100 : 0
            o.profitAngliData = o.type === 'regular' ? o.finalProfit - o.sellerProfit : 0
            o.percentageAngliData = o.type === 'regular' ? parseFloat((o.profitAngliData / o.totalWithoutIGV).toFixed(2)) : 0
            o.sellerProfitFinal = o.type === 'regular' ? o.sellerProfit : 0
            o.voucherType = o.client.typeDocument === 'RUC' ? 'F' : 'B'
            o.quote = o.type === 'regular' ? o.quote.code : '-'
            o.guide = o.type === 'regular' ? (o.quoteMovements.filter((m) => m.type === 'delivery').map((m) => {
              return m.document
            }).join(',')) : '-'
            o.paymentMethod = o.paymentMethod.value
            o.deadline = o.dueDate.getFullYear() + '-' + (o.dueDate.getMonth() + 1) + '-' + o.dueDate.getDate()
            o.seller = o.user.displayName
            o.observation = ''
          })

          console.log(list)

          const headerVal = ['businessName', 'code', 'state', 'currency', 'total', 'date', 'totalWithoutIGV', 'exchangeRatePurchase', 'totalPurchaseWithoutIGV',
            'profitPercentage', 'profitTotal', 'percentageToClient', 'clientAmount', 'finalProfit', 'sellerPercentage', 'sellerProfit', 'profitAngliData', 'percentageAngliData', 'sellerProfitFinal', 'voucherType', 'quote',
            'guide', 'paymentMethod', 'date', 'deadline', 'seller', 'observation']
          const headerTitle = ['Cliente', 'Nro. Comp', 'Estado', 'Moneda', 'Monto total', 'Fecha de emisión', 'Monto de venta sin IGV', 'Tipo de cambio en la Fecha de Compra', 'Monto de compra sin IGV'
            , '% antes del vendedor y cliente', 'Utilidad antes del vendedor y cliente', '% al cliente', 'Monto a recibir al cliente', 'Utildad antes de la comisión del vendedor', '% del vendedor', 'Monto al vendedor', 'Utilidad AngliData Final', '% Para AngliData Final', 'Monto a pagar al vendedor'
            , 'Tipo', 'Coti', 'Guia', 'Forma de pago', 'Fecha de emision 2', 'Fecha vcto. pago', 'Vendedor', 'Observacion']
          const data = this.formatJson(headerVal, list)
          excel.export_json_to_excel({
            header: headerTitle,
            data,
            filename: this.fileName,
            autoWidth: this.cellAutoWidth,
            bookType: this.selectedFormat
          })
          this.clearFields()
          this.initProgress = false
        })
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      }
    },
    clearFields () {
      this.filename = ''
      this.cellAutoWidth = true
      this.selectedFormat = 'xlsx'
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        // Add col name which needs to be translated
        // if (j === 'timestamp') {
        //   return parseTime(v[j])
        // } else {
        //   return v[j]
        // }

        return v[j]
      }))
    },
    /**
     * Add data of sidebar
     * */
    addDataSideBar (o) {
      this.list.unshift(o)
    },
    /**
     * Update data of sidebar
     * */
    updateDataSideBar (o) {
      const index = this.list.findIndex((d) => d.id === o.id)
      Object.assign(this.list[index], o)
    },
    /**
     * Get status color
     * @param status
     * @returns {string}
     */
    getStatusColor (status) {
      if (status === 'hold') return 'warning'
      if (status === 'send') return 'success'
      if (status === 'not_send') return 'dark'
      if (status === 'rejected') return 'danger'
      return 'success'
    },
    /**
     * Get state text
     * @param value
     * @returns {string}
     */
    getStateText (value) {
      if (value === 'hold') return 'Generada'
      if (value === 'send') return 'Enviada'
      if (value === 'not_send') return 'Error'
      if (value === 'rejected') return 'Anulada'
    },
    /**
     *
     * @returns {Promise<void>}
     */
    async loadSales () {
      let querySnap = {}
      if (this.$acl.check('admin')) {
        querySnap = await db.collection('sales')
            .orderBy('createdAt', 'desc')
            .limit(400).get()
      }
      else {
        querySnap = await db.collection('sales')
            .where('user.uid', '==', auth.currentUser.uid)
            .orderBy('createdAt', 'desc')
            .limit(400).get()
      }
      querySnap.forEach((doc) => {
        let obj = {
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt.toDate(),
          dueDate: doc.data().dueDate.toDate(),
        }
        if (obj.quote) {
          obj.quote = {
            ...doc.data().quote,
            createdAt: doc.data().quote.createdAt.toDate()
          }
        }
        this.list.push(obj)
      })
    }
    ,
    /**
     * Get quotes of firestore
     * @returns {Promise<void>}
     */
    async loadQuotes () {
      this.quotes = []
      let querySnap = {}
      if (this.$acl.check('admin')) {
        querySnap = await db.collection('quotes')
            .where('state', '==', true)
            .where('status', '==', 'inProcess')
            .orderBy('createdAt', 'desc')
            .limit(200).get()
      }
      else {
        querySnap = await db.collection('quotes')
            .where('user.uid', '==', auth.currentUser.uid)
            .where('state', '==', true)
            .where('status', '==', 'inProcess')
            .orderBy('createdAt', 'desc')
            .limit(200).get()
      }
      querySnap.forEach((doc) => {
        let quote = {
          id: doc.id,
          code: doc.data().code,
          client: doc.data().client,
          contact: doc.data().contact,
          currency: doc.data().currency,
          total: doc.data().total,
          igv: doc.data().igv,
          subTotal: doc.data().subTotal,
          products: doc.data().products,
          createdAt: doc.data().createdAt.toDate(),
          roundingType: doc.data().roundingType,
          status: doc.data().status,
          igvType: doc.data().igvType,
          link: doc.data().link
        }
        this.quotes.push(quote)
      })
    }
    ,
    /**
     * Get clients of firestore
     * @returns {Promise<void>}
     */
    async loadClients () {
      this.clients = []
      const querySnap = await db.collection('clients')
          .where('state', '==', true)
          .orderBy('businessName', 'asc').get()
      querySnap.forEach((doc) => {
        let client = {
          id: doc.id,
          document: doc.data().document,
          typeDocument: doc.data().typeDocument,
          businessName: doc.data().businessName,
          label: doc.data().businessName + ' - ' + doc.data().document,
          address: doc.data().address,
          department: doc.data().department,
          province: doc.data().province,
          district: doc.data().district,
          ubigeo: doc.data().ubigeo,
          email: doc.data().email,
          paymentMethod: doc.data().paymentMethod || null,
          retainer: doc.data().retainer || 'false'
        }
        this.clients.push(client)
      })
    }
    ,
    /**
     * Get products of algolia
     * @returns {Promise<void>}
     */
    async loadProducts () {
      // Search products in algolia, if search is empty list preview
      let response = await index.search('', {
        hitsPerPage: 20,
      })
      response.hits.forEach((p) => {
        this.products.push({
          description: p.description,
          sku: p.objectID.substr(0, 6).toUpperCase(),
          numberPart: p.numberPart,
          cost: p.price,
          id: p.objectID
        })
      })
      // End
    }
    ,
    /**
     * Low communication invoice confirm
     * @param tr
     */
    openCancelDialog (tr) {
      this.selectedInvoice = tr
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmación',
        text: `¿Deseas dar de baja el comprobante "${tr.code}"?`,
        accept: this.acceptDialogLowCommunication,
        cancel: '',
        parameters: [tr],
        acceptText: 'Dar de baja',
        cancelText: 'Cancelar'
      })
    },
    /**
     * Low communication send nubefact
     * @returns {Promise<void>}
     */
    async acceptDialogLowCommunication () {
      try {
        this.initProgress = true
        const response = await axios.post('https://southamerica-east1-anglidata-1aa70.cloudfunctions.net/nubefact-cancelInvoice', {
          document: {
            operacion: 'generar_anulacion',
            tipo_de_comprobante: this.selectedInvoice.client.typeDocument === 'RUC' ? 1 : 2,
            serie: this.selectedInvoice.serie,
            numero: this.selectedInvoice.sequence,
            motivo: 'BAJA POR ERROR DEL SISTEMA'
          }
        }, {
          headers: {
            Authorization: 'Bearer bd14b377-92f6-4741-8303-1f372131897c'
          }
        })
        // Update status and response to nubefact
        await db.collection('sales').doc(this.selectedInvoice.id).update({
          lowCommunicationNubefact: {
            ...response.data.nubefact
          },
          status: 'rejected'
        })
        const objInvoice = this.list.find((o) => o.id === this.selectedInvoice.id)
        objInvoice.status = 'rejected'
        objInvoice.lowCommunicationNubefact = {
          ...response.data.nubefact
        }
        this.selectedInvoice = {}
        this.$vs.notify({
          color: 'success',
          title: 'Comprobante de venta',
          text: 'Comunicación de baja realizada.'
        })
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: e.response.data.errors
        })
      } finally {
        this.initProgress = false
      }
    }
    ,
    /**
     * Resend invoice to nubefact confirm
     * @param tr
     */
    openResendDialog (tr) {
      this.selectedInvoice = tr
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmación',
        text: `¿Deseas enviar a Nubefact el comprobante "${tr.code}"?`,
        accept: this.sendNubefact,
        cancel: '',
        parameters: [tr],
        acceptText: 'Enviar',
        cancelText: 'Cancelar'
      })
    }
    ,
    /**
     * Send to Eprint
     * @param tr
     */
    openPrintDialog (tr) {
      this.selectedInvoice = tr
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmación',
        text: `¿Deseas imprimir remotamente el comprobante "${tr.code}"?`,
        accept: this.acceptDialogPrint,
        cancel: '',
        parameters: [tr],
        acceptText: 'Imprimir',
        cancelText: 'Cancelar'
      })
    }
    ,
    /**
     * Eprint
     * @returns {Promise<void>}
     */
    async acceptDialogPrint () {
      try {
        this.initProgress = true
        await axios.post('https://southamerica-east1-anglidata-1aa70.cloudfunctions.net/print-sendToPrint', {
          url: this.selectedInvoice.nubefact.enlace_del_pdf
        }, {
          headers: {
            Authorization: 'Bearer bd14b377-92f6-4741-8303-1f372131897c'
          }
        })

        this.selectedInvoice = {}
        this.$vs.notify({
          color: 'success',
          title: 'Comprobante de venta',
          text: 'Comprobante enviado a impresión.'
        })
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: e.response.data.errors
        })
      } finally {
        this.initProgress = false
      }
    }
    ,
    /**
     * Send invoice to Nubefact
     */
    async sendNubefact () {
      try {
        this.initProgress = true
        let sequence = this.selectedInvoice.sequence
        let total = this.selectedInvoice.total
        let subTotal = this.selectedInvoice.subTotal
        let igv = this.selectedInvoice.igv
        let serie = this.selectedInvoice.serie
        const items = []
        this.selectedInvoice.products.forEach((p) => {
          items.push(
              {
                'unidad_de_medida': 'NIU',
                'codigo': p.numberPart,
                'codigo_producto_sunat': '32130000',
                'descripcion': p.description,
                'cantidad': p.quantity,
                'valor_unitario': p.price,
                'precio_unitario': parseFloat((p.price * 1.18).toFixed(this.selectedInvoice.roundingType)),
                'descuento': '',
                'subtotal': parseFloat((p.price * p.quantity).toFixed(this.selectedInvoice.roundingType)),
                'tipo_de_igv': 1,
                'igv': parseFloat((p.price * p.quantity * 1.18).toFixed(this.selectedInvoice.roundingType)) - parseFloat((p.price * p.quantity).toFixed(this.selectedInvoice.roundingType)),
                'total': parseFloat((p.price * p.quantity * 1.18).toFixed(this.selectedInvoice.roundingType)),
                'anticipo_regularizacion': false,
                'anticipo_documento_serie': '',
                'anticipo_documento_numero': ''
              }
          )
        })
        // Get guides
        const guides = []
        if (this.selectedInvoice.type === 'regular') {
          this.selectedInvoice.observation = this.selectedInvoice.observation ? ', OC. Cliente: ' : 'OC. Cliente: '
          const querySnap = await db.collection('guides').where('quote.id', '==', this.selectedInvoice.quote.id).where('status', '==', 'send').get()
          querySnap.forEach((doc) => {
            guides.push({
              'guia_tipo': '1',
              'guia_serie_numero': doc.data().code
            })
            if (doc.data().purchaseOrder) {
              this.selectedInvoice.observation += doc.data().purchaseOrder.trim() + ', '
            }
          })
        }
        // Split emails
        const clientDoc = await db.collection('clients').doc(this.selectedInvoice.client.id).get()
        const emails = clientDoc.data().email !== '_' ? clientDoc.data().email.split(',') : []
        // End
        const document = {
          'operacion': 'generar_comprobante',
          'tipo_de_comprobante': this.selectedInvoice.client.typeDocument === 'RUC' ? 1 : 2,
          'serie': serie,
          'numero': sequence,
          'sunat_transaction': 1,
          'cliente_tipo_de_documento': this.selectedInvoice.client.typeDocument === 'RUC' ? '6' : '1',
          'cliente_numero_de_documento': this.selectedInvoice.client.document + '',
          'cliente_denominacion': clientDoc.data().businessName,
          'cliente_direccion': clientDoc.data().address + '-' + clientDoc.data().department + '-' + clientDoc.data().province + '-' + clientDoc.data().district,
          'fecha_de_emision': new Date().getDate() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getFullYear(),
          'moneda': this.selectedInvoice.currency.id,
          'porcentaje_de_igv': 18,
          'total_gravada': subTotal,
          'total_igv': igv,
          'total': total,
          'observaciones': this.selectedInvoice.observation,
          'enviar_automaticamente_a_la_sunat': true,
          'enviar_automaticamente_al_cliente': true,
          'formato_de_pdf': '',
          'guias': [
            ...guides
          ],
          'medio_de_pago': this.selectedInvoice.paymentMethod.id === 0 ? 'CONTADO' : 'CRÉDITO',
          'condiciones_de_pago': this.selectedInvoice.paymentMethod.value.toUpperCase(),
          'tipo_de_cambio': this.selectedInvoice.exchangeRate,
          'cliente_email': emails.length === 1 ? emails[0].trim() : '',
          'cliente_email_1': emails.length === 2 ? emails[1].trim() : '',
          'cliente_email_2': emails.length === 3 ? emails[2].trim() : ''
        }
        // Bills
        if (this.selectedInvoice.paymentType === 'regular') {
          if (this.selectedInvoice.paymentMethod.id !== 0) {
            let billTotal = 0
            if (this.selectedInvoice.regimeType.percentage === 0) {
              billTotal = total
            }
            else {
              billTotal = total - (total * this.selectedInvoice.regimeType.percentage / 100)
            }
            let dueDate = new Date(new Date().setDate(new Date().getDate() + this.selectedInvoice.paymentMethod.id))
            document.venta_al_credito = [{
              cuota: '1',
              importe: billTotal,
              fecha_de_pago: dueDate.getDate() + '-' + (dueDate.getMonth() + 1) + '-' + dueDate.getFullYear()
            }]
            document.fecha_de_vencimiento = dueDate.getDate() + '-' + (dueDate.getMonth() + 1) + '-' + dueDate.getFullYear()
          }
          else {
            let dueDate = new Date(new Date().setDate(new Date().getDate() + 1))
            document.fecha_de_vencimiento = dueDate.getDate() + '-' + (dueDate.getMonth() + 1) + '-' + dueDate.getFullYear()
          }
        }
        else {
          let billTotal = 0
          if (this.selectedInvoice.regimeType.percentage === 0) {
            billTotal = total
          }
          else {
            billTotal = total - (total * this.selectedInvoice.regimeType.percentage / 100)
          }
          document.venta_al_credito = []
          for (let i = 1; i <= this.selectedInvoice.fees.id; i++) {
            let dueDate = new Date(new Date().setDate(new Date().getDate() + (this.selectedInvoice.frequency.id * i)))
            document.venta_al_credito.push({
              cuota: i + '',
              importe: this.toFixedFloor(billTotal / this.selectedInvoice.fees.id, 2),
              fecha_de_pago: dueDate.getDate() + '-' + (dueDate.getMonth() + 1) + '-' + dueDate.getFullYear()
            })
          }
        }
        // End
        console.log(this.selectedInvoice.fees)
        console.log(document.venta_al_credito)

        // Regime
        if (this.selectedInvoice.regimeType.percentage !== 0) {
          if (this.selectedInvoice.regimeType.percentage === 3) {
            document.retencion_tipo = 1
            document.retencion_base_imponible = total
            document.total_retencion = parseFloat((total * 3 / 100).toFixed(2))
          }
          else if (this.document.regimeType.percentage === 12 || this.document.regimeType.percentage === 10) {
            document.sunat_transaction = 30
            document.detraccion = true
            document.detraccion_tipo = 35
            document.detraccion_total = (total * (this.document.regimeType.percentage / 100))
            document.detraccion_porcentaje = 12
            document.medio_de_pago_detraccion = 1
          }
        }
        const response = await this.$http.post('https://southamerica-east1-anglidata-1aa70.cloudfunctions.net/nubefact-sendInvoices', {
          document,
          items
        }, {
          headers: {
            'Authorization': 'Bearer bd14b377-92f6-4741-8303-1f372131897c'
          }
        })

        // Update invoice
        await db.collection('sales').doc(this.selectedInvoice.id).update({
          nubefact: {
            ...response.data.nubefact
          },
          status: response.data.nubefact.aceptada_por_sunat ? 'send' : 'not_send'
        })
        this.selectedInvoice.status = response.data.nubefact.aceptada_por_sunat ? 'send' : 'not_send'
        this.selectedInvoice.nubefact = {
          ...response.data.nubefact
        }
        // End
        this.$vs.notify({
          color: 'success',
          title: 'Comprobante de venta',
          text: 'Comprobante enviado correctamente.'
        })
        // Open PDF in browser
        window.open(response.data.nubefact.enlace_del_pdf, 'blank')
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: e.response.data.errors,
          time: 7000
        })
        return -1
      } finally {
        this.initProgress = false
      }
    }
    ,
    /**
     * Get status
     * @returns {Promise<void>}
     */
    async getStatus (obj) {
      try {
        this.initProgress = true
        const responseNubefact = await this.$http.post('https://southamerica-east1-anglidata-1aa70.cloudfunctions.net/nubefact-getStatus', {
          type: obj.client.typeDocument === 'RUC' ? 1 : 2,
          serie: obj.serie,
          sequence: obj.sequence
        }, {
          headers: {
            'Authorization': 'Bearer bd14b377-92f6-4741-8303-1f372131897c'
          }
        })
        // Update sale in firestore
        if (responseNubefact.data.nubefact.aceptada_por_sunat) {
          await db.collection('sales').doc(obj.id).update({
            nubefact: {
              ...responseNubefact.data.nubefact
            },
            status: 'send'
          })
          // Open PDF in browser
          window.open(responseNubefact.data.nubefact.enlace_del_pdf, 'blank')
          obj.status = 'send'
          obj.nubefact = responseNubefact.data.nubefact
          // End
        }
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Error',
          time: 7000
        })
      } finally {
        this.initProgress = false
      }
    },
    /**
     * Reset filters
     * @returns {Promise<void>}
     */
    async resetFilters () {
      this.initProgress = true
      this.list = []
      this.startDate = null
      this.endDate = null
      this.client = null
      await this.loadSales()
      this.$refs.filterCard.removeRefreshAnimation()
      this.initProgress = false
      this.from = 0
    }
    ,
    /**
     * Filter by date
     */
    async filterByDate () {
      try {
        if (this.startDate && this.endDate) {
          this.initProgress = true
          this.list = []
          // Set start date
          this.startDate.setHours(0)
          this.startDate.setMinutes(0)
          // Set end date
          this.endDate.setHours(23)
          this.endDate.setMinutes(59)

          const starTimes = Timestamp.fromDate(this.startDate)
          const endTimes = Timestamp.fromDate(this.endDate)
          let querySnapshot = {}

          if (this.from === 0) {
            if (this.client) {
              if (this.$acl.check('admin')) {
                querySnapshot = await db.collection('sales')
                    .where('createdAt', '>=', starTimes)
                    .where('createdAt', '<=', endTimes)
                    .where('client.id', '==', this.client.id).orderBy('createdAt', 'desc').get()
              }
              else {
                querySnapshot = await db.collection('sales')
                    .where('user.uid', '==', auth.currentUser.uid)
                    .where('createdAt', '>=', starTimes)
                    .where('createdAt', '<=', endTimes)
                    .where('client.id', '==', this.client.id).orderBy('createdAt', 'desc').get()
              }
            }
            else {
              if (this.$acl.check('admin')) {
                querySnapshot = await db.collection('sales')
                    .where('createdAt', '>=', starTimes)
                    .where('createdAt', '<=', endTimes).orderBy('createdAt', 'desc').get()
              }
              else {
                querySnapshot = await db.collection('sales')
                    .where('user.uid', '==', auth.currentUser.uid)
                    .where('createdAt', '>=', starTimes)
                    .where('createdAt', '<=', endTimes).orderBy('createdAt', 'desc').get()
              }
            }
          }
          else {
            querySnapshot = await db.collection('sales')
                .where('sequence', '>=', this.from)
                .where('paidOut', '==', 0)
                .orderBy('sequence', 'asc').get()
          }
          querySnapshot.forEach((doc) => {
            let obj = {
              id: doc.id,
              ...doc.data(),
              createdAt: doc.data().createdAt.toDate(),
              dueDate: doc.data().dueDate.toDate()
            }
            if (obj.status === 'send') {
              this.list.push(obj)
            }
          })
        }
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.initProgress = false
      }
    }
    ,
    /**
     * Record payment
     */
    recordPayment () {
      if (this.selected.status === 'send') {
        this.showPaymentsSidebar = true
        this.sidebarData = this.selected
      }
      else {
        this.$vs.notify({
          color: 'warning',
          title: 'Comprobante de venta',
          text: 'El comprobante debe haber sido enviado.'
        })
      }
    }
    ,
    /**
     * Listen keyboard
     */
    listenKeyboard (ev) {
      if (this.selected.id && !this.showPaymentsSidebar && !this.showNewSidebarWC && !this.showNewSidebar && !this.activePrompt && !this.activePromptCommissions) {
        if (ev.keyCode === 79) { // Open Link (O)
          if (this.selected.id) {
            this.openLink(this.selected.nubefact.enlace_del_pdf)
          }
        }
        else if (ev.keyCode === 73) { // Print (I)
          if (this.selected.id) {
            this.openPrintDialog(this.selected)
          }
        }
        else if (ev.keyCode === 80 && this.$acl.check('admin')) { // Payments (P)
          if (this.selected.id) {
            this.recordPayment()
          }
        }
        else if (ev.keyCode === 65) { // Cancel (A)
          if (this.selected.id && this.selected.status === 'send') {
            this.openCancelDialog(this.selected)
          }
        }
        else if (ev.keyCode === 77) { // Movements (M)
          this.showMovements = true
          this.sidebarData = this.selected
        }
        else if (ev.keyCode === 82 && this.selected.status === 'hold') { // Resend (R)
          this.openResendDialog(this.selected)
        }
      }
    }
    ,
    /**
     * Low communication invoice confirm
     * @param tr
     */
    faqDialog (tr) {
      this.selectedInvoice = tr
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Error de Sunat',
        text: `${tr.nubefact.sunat_description}`,
        accept: '',
        cancel: '',
        parameters: [tr],
        acceptText: 'Ok',
        cancelText: 'Cerrar'
      })
    },
    /**
     *
     * @param num
     * @param decimalPlaces
     * @returns {number}
     */
    toFixedFloor (num, decimalPlaces) {
      const factor = Math.pow(10, decimalPlaces);
      return Math.floor(num * factor) / factor;
    }
  }
}
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 10px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}

.product-name {
  max-width: 23rem;
}
</style>
